import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseImgUrl } from '../../config';
import { clearAllCookies, getCookie } from '../../utils';
import { Popover } from 'antd'
import './Header.less'
import { LoginOut } from '../../api';
import SolutionPop from '../SolutionPop/SolutionPop';
import ResearchPop from '../ResearchPop/ResearchPop';
import AboutUsModal from '../AboutUsModal';


interface Props {
}

const initList: { open?: boolean } & any[] = [
    {
        label: '首页',
        key: 'home',
        path: 'home'
    },
    {
        label: '行业解决方案',
        key: 'app',
        path: 'solution',
        hasArrow: true,
        pop: <SolutionPop />,
        open: false
    },
    {
        label: '产品',
        key: 'production',
        path: 'production',
    },
    {
        label: '研究院',
        key: 'research',
        path: 'research',
        hasArrow: true,
        pop: <ResearchPop />,
        open: false
    },
    {
        label: '洞察报告',
        key: 'report',
        path: 'report'
    },
    {
        label: '关于我们',
        key: 'aboutUs',
        path: 'aboutUs',
        hasArrow: true,
        pop: <AboutUsModal />,
        open: false
    },
];


const Header: React.FC<Props> = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const [current, setCurrent] = useState<string>();
    const [isLogin] = useState(getCookie('token') !== undefined)
    const [username] = useState(getCookie('username'))
    const [list, setList] = useState(initList)

    useEffect(() => {
        setCurrent(pathname.split('/')?.[1])
    }, [pathname])
    const renderBox = () => {
        return <div className='userinfo_box'>
            <div className='username_box'>
                {username}
            </div>
            <div className='loginout_box' onClick={() => {
                LoginOut({ token: getCookie('token') }).then(res => {
                    if (res?.data?.code === 200) {
                        clearAllCookies()
                        navigate('/login?type=1')
                    }
                })
            }}>
                退出登录
            </div>
        </div>
    }

    return (
        <div className='common_section header_section'>
            <div className='common_container header_container'>
                <div className='header_flex_box'>
                    <img className='company_logo' src={`${baseImgUrl}company-logo.png`} alt='' onClick={() => {
                        navigate('/home')
                    }} />
                    <div className='nav_container' >
                        {list.map((item, idx) => {
                            if (item.hasArrow && item.pop) {
                                return <div key={item.key} className={`nav_item ${current?.includes(item.path) ? 'active' : ''}`} >
                                    <Popover title="" content={() => {
                                        return React.cloneElement(item.pop, {
                                            closePop: (nav: string, type?: any) => {
                                                navigate(`${nav}?type=${type}`)
                                                item.open = false
                                                setList([...list])
                                            }
                                        })
                                    }} trigger='hover' key={item.key} open={item.open}
                                        onOpenChange={(e) => {
                                            item.open = e
                                            setList([...list])
                                        }}
                                    >
                                        <div className={`nav_item ${current?.includes(item.path) ? 'active' : ''}`}
                                            onClick={() => {
                                                item.open = false
                                                setList([...list])
                                                setCurrent(item.path)
                                                navigate(item.path)
                                            }}
                                        >
                                            {item.label}
                                            {item?.hasArrow && <i />}
                                        </div>
                                    </Popover>
                                </div>
                            } else {
                                return <div onClick={() => {
                                    setCurrent(item.path)
                                    navigate(item.path)
                                }} key={item.key} className={`nav_item ${current === item.path ? 'active' : ''}`} >
                                    {item.label}
                                    {item?.hasArrow && <i />}
                                </div>
                            }
                        })}
                    </div>
                </div>
                <div className='header_flex_box'>
                    {
                        isLogin ? <Popover
                            content={renderBox}
                            title=""
                            trigger="hover"
                        >
                            <div className='user_box'></div>
                        </Popover> :
                            <div className='login_box'>
                                <div className='login_text'
                                    onClick={() => {
                                        navigate('/login?type=2')
                                    }}
                                >注册</div>
                                <div className='login_box_child'
                                    onClick={() => {
                                        navigate('/login?type=1')
                                    }}
                                >登录</div>
                            </div>

                    }
                    <div className='backOldBtn'
                        onClick={() => {
                            window.open('//past.data100.com ')
                        }}
                    >返回旧版</div>
                </div>


            </div>

        </div >

    );
};

export default Header;