import axios from "axios";
import { getCookie } from "./utils";
import { JSEncrypt } from "jsencrypt";
const BASE_URL = "//consolecs.data100.com/api/"; // 测试接口地址
// const BASE_URL = "//console.data100.com/api/"; // 线上接口地址

const encrypt = new JSEncrypt();
encrypt.setPublicKey(
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4OfVhOyhnxWD8NtzXLbRZ+OoQWMWH82mIpBtV08+6Mp1nu04LrVS4GSEl6r6PpdF+fhZ35gZnSEHRrkcDPo7wtcL9cMRLcwcDEq6nF9qkOuF/VRV0CVoRq4lBZzXf0Im57IWEF1jFkXCwIoX9MRzIXARIz8fnYEVttnuTKJCBbb8WNJ0YN9wokiKJctBTSIaJh3lOhfzHw7zlVflIJSFqepqcgLQOcgKBp++Oe8JYW2WLjzTC4rDpYIKRyVFOAuSosoHZUa0YvIHNriIBohVgzbRM8lRbbDviDeMAjj1FSrHkTqVAlDNPwnDGqnUN04/w/EyCmHnZicWKCQrfHD1gQIDAQAB"
);

axios.interceptors.request.use((config) => {
  // 为请求头添加x-access-token字段为服务端返回的token
  config.headers["token"] = getCookie("token");
  config.headers["sign"] = encrypt.encrypt(
    Math.floor(Date.now() / 1000).toString()
  );
  // return config是固定用法 必须有返回值
  return config;
});

export const getMobileCode = (data: any) => {
  return axios.post(`${BASE_URL}user/getMobileCode`, data);
};

export const postLogin = (data: any) => {
  return axios.post(`${BASE_URL}user/login`, data);
};

export const postRegister = (data: any) => {
  return axios.post(`${BASE_URL}user/register`, data);
};

export const LoginOut = (data: any) => {
  return axios.post(`${BASE_URL}user/LoginOut`, data);
};

export const getNewsList = () => {
  return axios.post(`${BASE_URL}news/getList`);
};

export const getReportList = (data: {
  report_type: string | number;
  time: string;
  page: number;
}) => {
  return axios.post(`${BASE_URL}report/getList`, data);
};

export const getReportDetail = (data: { id: number }) => {
  return axios.post(`${BASE_URL}report/getDetail`, data);
};

export const getReportCarouselList = () => {
  return axios.post(`${BASE_URL}report/carouselList`);
};

export const getRecommendList = (data: { currentId: number }) => {
  return axios.post(`${BASE_URL}report/recommendList`, data);
};

export const getNewsRecommendList = (data: { currentId: number }) => {
  return axios.post(`${BASE_URL}news/recommendList`, data);
};

export const getCompanyList = (data: { searchName: string }) => {
  console.log(data, "data");
  return axios.post(`${BASE_URL}user/company`, data);
};

export const addDownloadRecord = (data: { currentId: any }) => {
  return axios.post(`${BASE_URL}report/addDownloadRecord`, data);
};

export const subscribeEmails  = (data: { subscribe_email: string,industry:string }) => {
  return axios.post(`${BASE_URL}report/subscribeEmails`, data);
};

 