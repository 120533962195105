import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import DynamicRoutes from './router/dynamic';
import { StyleProvider } from '@ant-design/cssinjs';
import './App.less'
import vconsole from 'vconsole'
const ScrollToTop = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/") {
      // 根据 URL 中的参数设置默认选中的子组件
      navigate(`/home`, { replace: true });
    }
    window.scrollTo(0, 0);
    document.getElementById('root')?.scrollTo(0,0)
  }, [pathname, search, navigate]);
  return null;
}
const App = () => {
  const [isH5, setIsH5] = useState(false)
  useEffect(() => {
    new vconsole()
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const handleWindowResize = () => {
    const clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    const html_ = document.getElementsByTagName('html')[0]
    if (clientWidth < 768) {
      setIsH5(true)
      html_.style.fontSize = clientWidth / 375 + 'px'
      console.log(clientWidth / 375)
    } else {
      setIsH5(false)
      html_.style.fontSize = '14px'
    }
  }
  return (
    <>
      <StyleProvider hashPriority="high">
        <BrowserRouter basename=''>
          <ScrollToTop />
          <DynamicRoutes isH5={isH5}/>
        </BrowserRouter>
      </StyleProvider>
    </>
  );
}

export default App;
